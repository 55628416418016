export default {
	host:'https://server.tongchengwl.com',
	port:2469,
	socketurl:'wss://server.tongchengwl.com:3469'
}

// export default {
// 	host:'https://bgyxrtc0.haoleen.com',
// 	port:2469,
// 	socketurl:'wss://bgyxrtc0.haoleen.com:3469'
// }